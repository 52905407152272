import { useMantineTheme } from '@mantine/core'

export const useAppTheme = () => {
  const theme = useMantineTheme()

	return {
		...theme,
		...themeColors,
		navHeaderBackground: themeColors.primaryBlue,
		navHeaderBorder: themeColors.primaryGreen,
		//for mobile responsive sizing in MediaQuery overrides
		titleStyles: {
			h6: {
				fontSize: '18px',
				lineHeight: '26px',
			},
			h5: {
				fontSize: '20px',
				lineHeight: '28px',
			},
			h4: {
				fontSize: '24px',
				lineHeight: '32px',
			},
			h3:{
				fontSize: '40px',
				lineHeight: '48px',
			},
			h2: {
				fontSize: '56px',
				lineHeight: '64px',
			},
			h1: {
				fontSize: '72px',
				lineHeight: '80px',
			},
		},
		resourceCardWidth: '420px',
		informationLinkCardWidth: '420px',
		navHeaderHeight: 96,
		maxAppWidth: 2000,
		maxContentWidth: 1400,
	}
}

export const themeColors = {
  white: '#FEFEFD',
  beige: '#FAF2F0',
	darkBeige: "#DBD4D2",
  grey: '#D2D4DA',
  darkGrey: '#696A6D',
	mediumGrey: '#909296',
  lightGrey: '#F1F3F5',
  black: '#101223',
  primaryBlue: '#008DB9',
  primaryGreen: '#5aaf75',
  darkBlue: '#007194',
  darkGreen: '#428055',
  mutedBlue: '#3E97B2',
  mutedGreen: '#65A87A',
  red: '#D40E0E',
  darkRed: '#A93226',
  mutedRed: '#CD6155',
  lightBlue: '#008DB924',
}
