import React, { ReactNode } from 'react'
import { Link } from 'gatsby'
import { useAppTheme } from '../../styling/useAppTheme'
import { useNavStyles } from './navStyles'
import { RouteNames } from '../../constants/RouteNames'

type CustomNavLinkProps = {
  children: ReactNode;
  path: string;
  isSubLink?: boolean;
  onClick?: () => void;
}

export const CustomNavLink = ({ children, path, isSubLink, onClick }: CustomNavLinkProps) => {
  const { classes } = useNavStyles();
  const appTheme = useAppTheme();

  return (
    <Link
      className={isSubLink? classes.subLink : classes.link}
      activeStyle={{
        color: isSubLink ? appTheme.darkBlue : appTheme.white,
        backgroundColor: isSubLink ? 'inherit' : appTheme.darkBlue,
        fontFamily: 'OpenSansSemiBold'
      }}
      to={path}
      onClick={onClick}
      partiallyActive={path !== RouteNames.HOME} //nested paths display link as active
    >
      {children}
    </Link>
  )
}