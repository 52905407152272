import React, { ReactNode } from 'react'
import { Flex } from '@mantine/core'
import { CustomFonts } from "../styling/CustomFonts";
import '../styling/global.css';
import { NavHeader } from "./nav/NavHeader";
import { PageProps } from "gatsby";
import { ThemeWrapper } from "../styling/ThemeWrapper";
import { useAppTheme } from "../styling/useAppTheme";
import { CustomFooter } from "./CustomFooter";
import { Helmet } from 'react-helmet';
import { APP_NAME } from '../constants/strings'

type LayoutProps = {
    children: ReactNode;
    pageProps: PageProps;
}

export default function Layout({ children, pageProps }: LayoutProps) {
    const path = pageProps?.location?.pathname ? pageProps.location.pathname : '';
    const appTheme = useAppTheme();

    return (
        <ThemeWrapper>
						<Helmet>
							<title>{APP_NAME}</title>
						</Helmet>
            <CustomFonts />

            <Flex
              direction={'column'}
              justify={'flex-start'}
              align={'stretch'}
              h={'100vh'}
              bg={appTheme.white}
              style={{
                  padding: 0,
                  margin: 0,
              }}
            >
              <NavHeader currentPath={path} />

              <Flex
                direction={'column'}
                justify={'space-between'}
                align={'stretch'}
                pt={appTheme.navHeaderHeight}
                bg={appTheme.darkBeige}
                style={{
                    flex: 1,
                }}
              >
                <Flex
                  direction={'column'}
                  bg={appTheme.white}
                  p={0}
                  maw={appTheme.maxAppWidth}
                  w={'100%'}
                  m={'0 auto'}
                  style={{
                      flex: 1,
                  }}
                >
                  {children}
                </Flex>

                <CustomFooter />
              </Flex>
            </Flex>
        </ThemeWrapper>
    );
}
