import React from 'react'
import { RouteNames } from '../constants/RouteNames'
import { Link } from 'gatsby'
import { useAppTheme } from '../styling/useAppTheme'

export type InternalLinkProps = {
	path: RouteNames;
	label?: string;
	bold?: boolean;
}

export const InternalLink = ({ path, label, bold }: InternalLinkProps) => {
	const appTheme = useAppTheme();

	return (
		<Link
			to={path}
			style={{
				color: appTheme.primaryBlue,
				textDecoration: 'none',
				fontFamily: bold ? 'OpenSansBold' : appTheme.fontFamily,
				fontSize: 'inherit'
		}}
		>
			{label}
		</Link>
	)
}
