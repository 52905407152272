import React from 'react'
import { Anchor, Flex, Paper, Text } from '@mantine/core'
import { useAppTheme } from "../styling/useAppTheme";
import { APP_NAME } from '../constants/strings'
import { InternalLink } from './InternalLink'
import { RouteNames } from '../constants/RouteNames'

export const CustomFooter = () => {
	const appTheme = useAppTheme();

	return (
		<Flex
			direction={'row'}
			justify={'space-between'}
			align={'center'}
			h={'auto'}
			w={'100%'}
			p={30}
			bg={appTheme.black}
			wrap={'wrap'}
		>
			<Paper fz={16} bg={'transparent'} style={{ color: appTheme.white }}>
				<Text>
					{'For non-commercial use only. Contact '}
					<Anchor href={'mailto:ttoinfo@wayne.edu'}>ttoinfo@wayne.edu</Anchor>
					{' for licensing information.'}
				</Text>

				<Text>
					&copy;
					{' 2024 Wayne State University. All Rights Reserved. '}
					<InternalLink path={RouteNames.TERMS_AND_CONDITIONS} label={'Terms and Conditions.'} />
				</Text>
			</Paper>

			<Text
				color={appTheme.white}
				ff={'OpenSansBold'}
				fz={24}
				py={10}
				fs={'italic'}
				style={{
					whiteSpace: 'nowrap'
				}}
			>
				{APP_NAME}
			</Text>
		</Flex>
	)
}
