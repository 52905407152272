import React from 'react';
import { MantineProvider } from '@mantine/core';
import { customTheme } from './theme';

/*
 	Render any necessary theme providers in this component:
	MantineProvider, NotificationsProvider, ModalsProvider, SpotlightProvider, etc.
	This will wrap the entire App
*/
export function ThemeWrapper(props: { children: React.ReactNode }) {
	return (
			<MantineProvider theme={{ ...customTheme }} withGlobalStyles withNormalizeCSS>
				{props.children}
			</MantineProvider>
	);
}
