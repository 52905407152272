export enum RouteNames {
  HOME = '/',

  //Information
  INFORMATION = '/information',

  //Cancer and Money
  CANCER_AND_MONEY = '/information/cancer-and-money',
  PAYING_FOR_TREATMENT = '/information/cancer-and-money/paying-for-treatment',
  CAREGIVERS = '/information/cancer-and-money/caregivers',
  CANCER_RELATED_COSTS = '/information/cancer-and-money/cancer-related-costs',
  REPLACING_INCOME = '/information/cancer-and-money/replacing-income',
	DISCUSSING_COST_CONCERNS = '/information/cancer-and-money/discussing-cost-concerns',

  //Employment
  EMPLOYMENT = '/information/employment',
  BENEFITS = '/information/employment/benefits',
  PROTECTIONS_FMLA = '/information/employment/protections-fmla',
  PROTECTIONS_PMLA = '/information/employment/protections-pmla',
  PROTECTIONS_ADA = '/information/employment/protections-ada',
  WORKING_WITH_HR = '/information/employment/working-with-hr',

  //Health Insurance
  INSURANCE = '/information/insurance',
  MEDICARE = '/information/insurance/medicare',
  MEDICAID = '/information/insurance/medicaid',
  PRIVATE_INSURANCE = '/information/insurance/private-insurance',
  UNDERSTANDING_INSURANCE = '/information/insurance/understanding-and-using',

  //WEBINAR_LIBRARY
  WEBINAR_LIBRARY = '/information/webinar-library',

  RESOURCE_CONNECT = '/resources',
  BLOG = '/blog',
  ABOUT_US = '/about',
  CONTACT_US = '/contact',
  NOT_FOUND = '/404',
  SEARCH_RESULTS = '/search',
	TERMS_AND_CONDITIONS = '/terms-and-conditions',
}

export enum PageSections {
	//Replacing Income
	REPLACING_INCOME_EMPLOYER = 'employer',
	REPLACING_INCOME_PRIVATE_INSURANCE = 'private-insurance',
	REPLACING_INCOME_GOVERNMENT_PROGRAM = 'government-program',
	REPLACING_INCOME_SSI = 'ssi',
	REPLACING_INCOME_SSDI = 'ssdi',
	REPLACING_INCOME_SHORT_TERM_DISABILITY = 'short-term-disability',
	REPLACING_INCOME_LONG_TERM_DISABILITY = 'long-term-disability',

	//Private Insurance
	PRIVATE_INSURANCE_EMPLOYER_SPONSORED = 'employer-sponsored',
	PRIVATE_INSURANCE_INDIVIDUAL = 'individual',
	PRIVATE_INSURANCE_HMO = 'hmo',
	PRIVATE_INSURANCE_PPO = 'ppo',

	//Medicare
	MEDICARE_PART_D = 'part-d',
}
