import { MantineThemeOverride } from '@mantine/core';
import { themeColors } from "./useAppTheme";

export const customTheme: MantineThemeOverride = {
	globalStyles: () => ({
		body: {
			boxSizing: 'border-box'
		}
	}),
	fontFamily: 'OpenSans, sans-serif',
	fontSizes: {
		xs: 12,
		sm: 14,
		md: 18,
		lg: 24,
		xl: 32
	},
	spacing: {
		xs: 4,
		sm: 8,
		md: 16,
		lg: 24,
		xl: 32
	},
	// //default shadows built in: xs, sm, md, lg, xl - can override any/all
	shadows: {
		xs: '2px 2px 2px 0px rgba(0, 0, 0, .1)',
		sm: '1px 1px 4px 1px rgba(0, 0, 0, .1)',
		md: '2px 2px 4px 2px rgba(0, 0, 0, .1)',
		lg: '3px 3px 5px 3px rgba(0, 0, 0, .1)',
		xl: '4px 4px 6px 4px rgba(0, 0, 0, .1)',
	},
	// loading spinner style- options: dots, bars, oval
	loader: 'dots',
	// uses Title component with prop "order" to specify which h tag (ex. order={1} is h1)
	headings: {
		fontFamily: 'OpenSansSemiBold, sans-serif',
		fontWeight: 400,
		//can override default sizes for h1 - h6
		sizes: {
			h1: { // xl
				fontSize: '72px',
				lineHeight: '80px',
			},
			h2: { // lg
				fontSize: '56px',
				lineHeight: '64px'
			},
			h3: { // md
				fontSize: '40px',
				lineHeight: '48px'
			},
			h4: { // sm
				fontSize: '24px',
				lineHeight: '32px',
			},
			h5: { // xs
				fontSize: '20px',
				lineHeight: '28px',
			},
			h6: { // xxs
				fontSize: '18px',
				lineHeight: '26px',
			}
		},
	},
	components: {
		Button: {
			defaultProps: {
				h: 'auto', // **
				lh: 'normal', // ** text could get cutoff without these 2 settings (auto height, normal line height)
				fz: '16px',
				p: 16,
				w: 'auto',
				miw: 'min(260px, 100%)',
				ff: 'OpenSansSemiBold, sans-serif',
				radius: '5px',
				m: 5,
			},
			styles: () => ({
				root: {
					color: themeColors.white,
					backgroundColor: themeColors.primaryBlue,
					border: `2px solid ${themeColors.primaryBlue}`,
					"&:disabled": {
						backgroundColor: themeColors.grey,
						color: themeColors.black
					},
					"&:hover": {
						backgroundColor: themeColors.darkBlue,
						color: themeColors.white
					},
					"&:active": {
						backgroundColor: `${themeColors.mutedBlue}`,
						color: themeColors.white
					},
				},
			}),
		},
		Anchor: {
			styles: () => ({
				root: {
					color: themeColors.primaryBlue,
					fontSize: 'inherit',
					wordWrap: 'break-word'
				}
			}),
		},
		TextInput: {
			defaultProps: {
				br: '4px',
			},
			styles: () => ({
				input: {
					...inputStyles
				},
				error: {
					textAlign: 'left',
				},
				rightSection: {
					svg: {
						stroke: themeColors.grey,
					},
				},
			}),
		},
		NumberInput: {
			defaultProps: {
				br: '4px',
			},
			styles: () => ({
				input: {
					...inputStyles
				},
				error: {
					textAlign: 'left',
				},
				rightSection: {
					svg: {
						stroke: themeColors.grey,
					},
				},
			}),
		},
		DatePicker: {
			defaultProps: {
				br: '4px',
			},
			styles: () => ({
				defaultProps: {
					rightSectionProps: {
						color: themeColors.grey,
					},
				},
				input: {
					...inputStyles
				},
				error: {
					textAlign: 'left',
				},
				rightSection: {
					svg: {
						stroke: themeColors.grey,
					},
				},
			}),
		},
		PasswordInput: {
			defaultProps: {
				br: '4px',
			},
			styles: () => ({
				input: {
					...inputStyles
				},
				innerInput: {
					padding: '24px 8px',
					'::placeholder': {
						color: themeColors.darkGrey,
					},
				},
				error: {
					textAlign: 'left',
				},
				rightSection: {
					svg: {
						stroke: themeColors.black,
					},
				},
			}),
		},
		Select: {
			defaultProps: {
				br: '4px',
				miw: '240px',
				rightSectionProps: {
					'::svg': {
						stroke: themeColors.grey,
					},
				},
			},
			styles: () => ({
				input: {
					...inputStyles,
				},
				error: {
					textAlign: 'left',
				},
				rightSection: {
					svg: {
						stroke: themeColors.grey,
					},
				},
			}),
		},
		MultiSelect: {
			defaultProps: {
				br: '4px',
			},
			styles: () => ({
				input: {
					border: `1px solid ${themeColors.grey}`,
					padding: '9px 8px',
					color: themeColors.black,
					fontSize: '16px',
				},
				searchInput: {
					'::placeholder': {
						color: themeColors.darkGrey,
					},
				},
				error: {
					textAlign: 'left',
				},
				rightSection: {
					svg: {
						stroke: themeColors.grey,
					},
				},
			}),
		},
		Checkbox: {
			defaultProps: {
				radius: 'sm',
				size: 'md',
			},
			styles: (theme) => ({
				input: {
					border: `2px solid ${themeColors.primaryBlue}`,
					borderRadius: theme.radius.sm,

					'&:checked': {
						backgroundColor: themeColors.primaryGreen,
						border: `2px solid ${themeColors.primaryGreen} !important`,
					},
				}
			})
		},
		Carousel: {
			styles: () => ({
				slide: {
					padding: '10px',
					paddingRight: '10px !important'
				},
				control: {
					color: themeColors.white,
					backgroundColor: themeColors.primaryBlue,
					'&[data-inactive]': {
						opacity: 0,
						cursor: 'default',
					},
				},
				indicator: {
					backgroundColor: themeColors.primaryBlue,
					height: '8px',
					marginBottom: '5px'
				}
			}),
		}
	},
	white: themeColors.white,
	black: themeColors.black,
};

const inputStyles = {
	border: `1px solid ${themeColors.grey}`,
	padding: '22px 8px',
	color: themeColors.black,
	fontSize: '16px',
	'::placeholder': {
		color: themeColors.darkGrey,
	},
}

