import React from 'react'
import {
  Box,
  Center,
  Collapse,
  Divider,
  Drawer,
  Flex,
  Group,
} from '@mantine/core'
import { RouteNames } from '../../constants/RouteNames'
import { Link } from 'gatsby'
import { IconChevronDown } from '@tabler/icons-react'
import { CustomNavLink } from './CustomNavLink'
import { SearchInput } from '../SearchInput'
import { useAppTheme } from '../../styling/useAppTheme'
import { useNavStyles } from './navStyles'
import { useDisclosure } from '@mantine/hooks'
import { APP_NAME } from '../../constants/strings'

type NavMenuProps = {
  isOpen: boolean;
  closeMenu: () => void;
  isActiveLink: (linkPath: RouteNames) => boolean;
}

//for display on mobile/small devices
export const NavMenu = ({ isOpen, closeMenu, isActiveLink }: NavMenuProps) => {
  const appTheme = useAppTheme();
  const { classes } = useNavStyles();
  const [subLinksOpened, { toggle: toggleSubLinks }] = useDisclosure(false);


  return (
    <Drawer
      opened={isOpen}
      onClose={closeMenu}
      className={classes.hiddenDesktop}
      title={APP_NAME}
      zIndex={1000000}
      size={'100%'}
      styles={() => ({
        content: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'stretch',
        },
        header: {
          textAlign: 'center',
          color: appTheme.black,
          fontFamily: 'OpenSansBold',
          backgroundColor: appTheme.white,
        },
        body: {
          padding: 0,
          margin: 0,
          overflow: 'hidden',
          height: '100%',
        },
        title: {
          paddingLeft: '20px',
          paddingTop: '20px',
          color: appTheme.darkBlue,
          fontFamily: 'OpenSansBoldItalic',
          fontSize: '22px'
        }
      })}
    >
      <div
        style={{
          width: '100%',
          backgroundColor: appTheme.white,
          flex: 1,
          margin: 0,
          padding: 0,
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Divider my="sm" color={'gray.2'} />

        <CustomNavLink path={RouteNames.HOME} onClick={closeMenu}>Home</CustomNavLink>

        <Link
          className={classes.link}
          activeStyle={{
            color: appTheme.white,
            backgroundColor: appTheme.darkBlue,
          }}
          partiallyActive={true} //nested paths display link as active
          to={RouteNames.INFORMATION} //needed for active display style
          onClick={(e) => {
            e.preventDefault(); //prevent navigate
            toggleSubLinks(); // show/hide sublinks
          }}
        >
          <Center inline>
            <Box component="span" mr={5}>
              Information
            </Box>
            <IconChevronDown
              size={16}
              color={isActiveLink(RouteNames.INFORMATION) ? appTheme.white : appTheme.primaryBlue}
            />
          </Center>
        </Link>

        <Collapse in={subLinksOpened}>
          <Flex direction={'column'} pl={20}>
            <CustomNavLink
              path={RouteNames.CANCER_AND_MONEY}
              isSubLink
              onClick={closeMenu}
            >
              Cancer and Money
            </CustomNavLink>
            <CustomNavLink
              path={RouteNames.EMPLOYMENT}
              isSubLink
              onClick={closeMenu}
            >
              Employment
            </CustomNavLink>
            <CustomNavLink
              path={RouteNames.INSURANCE}
              isSubLink
              onClick={closeMenu}
            >
              Health Insurance
            </CustomNavLink>
            <CustomNavLink
              path={RouteNames.WEBINAR_LIBRARY}
              isSubLink
              onClick={closeMenu}
            >
              Webinar Library
            </CustomNavLink>
          </Flex>
        </Collapse>

        <CustomNavLink path={RouteNames.RESOURCE_CONNECT} onClick={closeMenu}>Resource Connect</CustomNavLink>

				{/*TODO: temporarily hiding blog*/}
        {/*<CustomNavLink path={RouteNames.BLOG} onClick={closeMenu}>Blog</CustomNavLink>*/}

        <CustomNavLink path={RouteNames.ABOUT_US} onClick={closeMenu}>About Us</CustomNavLink>

        <CustomNavLink path={RouteNames.CONTACT_US} onClick={closeMenu}>Contact Us</CustomNavLink>

        <Divider my="sm" color={'gray.2'} />

        <Group position="center" grow p={20}>
          <SearchInput onSubmit={closeMenu} />
        </Group>
      </div>
    </Drawer>
  )
}
