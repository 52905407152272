import React from 'react'
import {
  Box,
  Burger,
  Center,
  Flex,
  Group,
  Header,
  HoverCard,
  Text,
} from '@mantine/core'
import { IconChevronDown } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import { useAppTheme } from '../../styling/useAppTheme'
import { Link, navigate } from 'gatsby'
import { RouteNames } from '../../constants/RouteNames'
import { NavMenu } from './NavMenu'
import { SearchInput } from '../SearchInput'
import { CustomNavLink } from './CustomNavLink'
import { useNavStyles } from './navStyles'
import { APP_NAME } from '../../constants/strings'

export type NavHeaderProps = {
  currentPath: string
}

export const NavHeader = ({ currentPath }: NavHeaderProps) => {
  const { classes } = useNavStyles()
  const appTheme = useAppTheme()

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false)

  const isActiveLink = (linkPath: RouteNames) => {
    return currentPath.includes(linkPath)
  }

	return (
		<Box pb={0} w={'100%'} pos={'fixed'} style={{ zIndex: 2000 }}>
			<Header
				height={appTheme.navHeaderHeight}
				px="md"
				bg={appTheme.navHeaderBackground}
				style={{
					borderBottom: `8px solid ${appTheme.navHeaderBorder}`,
				}}
			>
				<Group position="apart" sx={{ height: '100%' }}>
					<Text
						ff={'OpenSansBold'}
						fs={'italic'}
						color={appTheme.white}
						fz={36}
						onClick={() => navigate(RouteNames.HOME)}
						style={{ cursor: 'pointer' }}
					>
						{APP_NAME}
					</Text>

					<Group
						sx={{ height: '100%' }}
						spacing={0}
						className={classes.hiddenMobile}
					>
						<CustomNavLink path={RouteNames.HOME}>Home</CustomNavLink>

						<HoverCard
							width={'auto'}
							position="bottom"
							radius="sm"
							shadow="sm"
							withinPortal
							zIndex={2001}
						>
							<HoverCard.Target>
								<Link
									className={classes.link}
									activeStyle={{
										backgroundColor: appTheme.darkBlue,
									}}
									partiallyActive={true} //nested paths display link as active
									to={RouteNames.INFORMATION} //needed for active display style
									onClick={(e) => e.preventDefault()} //prevent navigate
								>
									<Center inline>
										<Box component="span" mr={5}>
											Information
										</Box>
										<IconChevronDown size={16} color={appTheme.white} />
									</Center>
								</Link>
							</HoverCard.Target>

              <HoverCard.Dropdown sx={{ overflow: 'hidden' }}>
                <Flex direction={'column'} miw={200}>
                  <CustomNavLink path={RouteNames.CANCER_AND_MONEY} isSubLink>
                    Cancer and Money
                  </CustomNavLink>
                  <CustomNavLink path={RouteNames.EMPLOYMENT} isSubLink>
                    Employment
                  </CustomNavLink>
                  <CustomNavLink path={RouteNames.INSURANCE} isSubLink>
                    Health Insurance
                  </CustomNavLink>
                  <CustomNavLink path={RouteNames.WEBINAR_LIBRARY} isSubLink>
                    Webinar Library
                  </CustomNavLink>
                </Flex>
              </HoverCard.Dropdown>
            </HoverCard>

            <CustomNavLink path={RouteNames.RESOURCE_CONNECT}>
              Resource Connect
            </CustomNavLink>

						{/*TODO: temporarily hiding blog*/}
            {/*<CustomNavLink path={RouteNames.BLOG}>Blog</CustomNavLink>*/}

            <CustomNavLink path={RouteNames.ABOUT_US}>About Us</CustomNavLink>

            <CustomNavLink path={RouteNames.CONTACT_US}>
              Contact Us
            </CustomNavLink>
          </Group>

					<Group className={classes.hiddenMobile}>
						<SearchInput w={180} />
					</Group>

					<Box
						style={{ cursor: 'pointer'}}
						onClick={toggleDrawer}
						className={classes.hiddenDesktop}
						p={20}
						pr={0}
					>
						<Burger
							color={appTheme.white}
							opened={drawerOpened}
						/>
					</Box>
        </Group>
      </Header>

      {/* Nav for mobile/small devices */}
      <NavMenu
        isOpen={drawerOpened}
        closeMenu={closeDrawer}
        isActiveLink={isActiveLink}
      />
    </Box>
  )
}
