import React from "react";
import { Global } from '@mantine/core';
import regular from '../assets/fonts/OpenSans-Regular.ttf';
import light from '../assets/fonts/OpenSans-Light.ttf';
import bold from '../assets/fonts/OpenSans-Bold.ttf';
import semiBold from '../assets/fonts/OpenSans-SemiBold.ttf';
import regularItalic from '../assets/fonts/OpenSans-Italic.ttf';
import lightItalic from '../assets/fonts/OpenSans-LightItalic.ttf';
import boldItalic from '../assets/fonts/OpenSans-BoldItalic.ttf';
import semiBoldItalic from '../assets/fonts/OpenSans-SemiBoldItalic.ttf';

export const CustomFonts = () => {
	return (
		<Global
			styles={[
				{
					'@font-face': {
						fontFamily: 'OpenSans',
						src: `url('${regular}') format("truetype")`,
						fontWeight: 400,
						fontStyle: 'normal',
					},
				},
				{
					'@font-face': {
						fontFamily: 'OpenSansLight',
						src: `url('${light}') format("truetype")`,
						fontWeight: 400,
						fontStyle: 'normal',
					},
				},
				{
					'@font-face': {
						fontFamily: 'OpenSansBold',
						src: `url('${bold}') format("truetype")`,
						fontWeight: 400,
						fontStyle: 'normal',
					},
				},
				{
					'@font-face': {
						fontFamily: 'OpenSansSemiBold',
						src: `url('${semiBold}') format("truetype")`,
						fontWeight: 400,
						fontStyle: 'normal',
					},
				},
				{
					'@font-face': {
						fontFamily: 'OpenSansItalic',
						src: `url('${regularItalic}') format("truetype")`,
						fontWeight: 400,
						fontStyle: 'normal',
					},
				},
				{
					'@font-face': {
						fontFamily: 'OpenSansLightItalic',
						src: `url('${lightItalic}') format("truetype")`,
						fontWeight: 400,
						fontStyle: 'normal',
					},
				},
				{
					'@font-face': {
						fontFamily: 'OpenSansBoldItalic',
						src: `url('${boldItalic}') format("truetype")`,
						fontWeight: 400,
						fontStyle: 'normal',
					},
				},
				{
					'@font-face': {
						fontFamily: 'OpenSansSemiBoldItalic',
						src: `url('${semiBoldItalic}') format("truetype")`,
						fontWeight: 400,
						fontStyle: 'normal',
					},
				},
			]}
		/>
	);
}
