import { createStyles } from '@mantine/core'
import { themeColors } from '../../styling/useAppTheme'

export const useNavStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    height: '50%',
    borderRadius: theme.radius.xs,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: 'none',
    color: theme.white,
    fontFamily: 'OpenSansSemiBold',
    fontSize: theme.fontSizes.md,
    marginLeft: 2,
    marginRight: 2,

    [theme.fn.smallerThan('lg')]: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      color: themeColors.primaryBlue,
    },

    ...theme.fn.hover({
      color: themeColors.white,
      backgroundColor: themeColors.darkBlue,
      cursor: "pointer"
    }),
  },

  subLink: {
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    borderRadius: theme.radius.xs,
    textDecoration: 'none',
    color: themeColors.primaryBlue,
    fontFamily: 'OpenSans',
    fontSize: theme.fontSizes.md,

    [theme.fn.smallerThan('lg')]: {
      fontFamily: 'OpenSans'
    },


    ...theme.fn.hover({
      backgroundColor: theme.colors.gray[0],
      color: themeColors.darkBlue,
      fontFamily: 'OpenSansSemiBold'
    }),

    '&:active': theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor: theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)px`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md}px calc(${theme.spacing.md} * 2)px`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[1]}`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan('lg')]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan('lg')]: {
      display: 'none',
    },
  },
}));