import React from 'react'
import { ActionIcon, Box, TextInput, TextInputProps } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import { useAppTheme, themeColors } from '../styling/useAppTheme'
import { useForm } from '@mantine/form'
import { IconChevronsRight } from '@tabler/icons-react'
import { RouteNames } from '../constants/RouteNames'
import { navigate } from 'gatsby'

type SearchInputProps = Omit<TextInputProps, 'onSubmit'> & {
  onSubmit?: () => void;
}

export const SearchInput = ({ onSubmit, ...textInputProps }: SearchInputProps) => {
  const appTheme = useAppTheme()

  const form = useForm({
    initialValues: {
      search: '',
    },
  })

  const hasSearchTerm = form.values.search.length > 0

  return (
    <form
      onSubmit={form.onSubmit((formValues) => {
        navigate(
          `${RouteNames.SEARCH_RESULTS}?q=${encodeURIComponent(
            formValues.search
          )}`
        )
        form.reset()

        // optional additional functionality
        if (onSubmit) {
          onSubmit();
        }
      })}
    >
      <Box pos="relative">
        <TextInput
          w={'100%'}
          miw={'200px'}
          placeholder={'Search'}
          icon={<IconSearch size={20} color={appTheme.grey} />}
          styles={() => ({
            input: {
              boxSizing: 'border-box',
              padding: '20px 8px',
              paddingRight: '40px',
              fontSize: '14px',
              '::placeholder': {
                color: appTheme.grey,
                fontWeight: 600,
              },
            },
          })}
          {...textInputProps}
          {...form.getInputProps('search')}
          autoComplete={'off'}
          autoCapitalize={'off'}
          autoCorrect={'off'}
        />
        {hasSearchTerm ? (
          <ActionIcon
            type="submit"
            right={8}
            top={'50%'}
            pos="absolute"
            variant="filled"
            bg={themeColors.primaryBlue}
            sx={{
              transform: 'translateY(-50%)',
              '&:hover': {
                backgroundColor: themeColors.darkBlue,
                transform: 'translateY(-50%)',
              },
            }}
          >
            <IconChevronsRight />
          </ActionIcon>
        ) : null}
      </Box>
    </form>
  )
}
